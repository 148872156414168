<template>
  <h5 class="card-title">Site Devices
    <button v-if="[7, 264, 579, 266, 605].includes(parseInt($store.state.auth.user.id))"
      class="btn btn-warning btn-sm ms-2" style="float: right;" data-bs-toggle="modal"
      data-bs-target="#importConfigModal">Import Config</button>
    <submit-button :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='config.downloading'
      @click="downloadConfig" :text="'Generate Config'"></submit-button>
    <submit-button v-if="changed && $hasRole('Support')" :background="'btn btn-sm btn-primary ms-2'"
      style="float: right;" :clicked='updating' :text="'Save Changes'" @click="savePendingUpdates"></submit-button>
    <button type="button" class="btn btn-sm btn-light" style="float: right;" title="Manage Columns"
      data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
      <i class="bx bxs-grid me-0"></i>
    </button>
    <button class="btn btn-primary btn-sm ms-2" style="float: right; margin-right: 10px;" @click="duplicateSelectedRow">Duplicate Selected</button>

  </h5>
  <br />
  <div v-if="$hasRole('Support')" class="row">
    <div class="col-12">
      <button v-if="[7, 264, 579, 266].includes(parseInt($store.state.auth.user.id))"
        class="btn btn-warning btn-sm ms-2" style="float: right;" data-bs-toggle="modal"
        data-bs-target="#migrateNodeModal">Migrate Selected</button>
      <button class="btn btn-primary btn-sm ms-2" style="float: right;" data-bs-toggle="modal"
        data-bs-target="#manageBulkCredentialsModal">Add Credential to Selected</button>
      <button class="btn btn-primary btn-sm ms-2" style="float: right;" data-bs-toggle="modal"
        data-bs-target="#manageModelForSelected">Set Product for Selected</button>
      <button class="btn btn-danger btn-sm ms-2" style="float: right;" @click="deleteSelected">Delete Selected</button>
      <input style="max-width: 250px;" ref="configfile" accept="text/csv" @change="selectFile"
        class="form-control form-control-sm float-end" type="file" id="formFile">
    </div>
  </div>

  <datalist id="product-list">
    <option v-for="product in products" :key="product.id" :value="product.name" />
  </datalist>
  <table id="siteNodesTable" v-if="loaded" class="table mb-0 table-hover">
    <thead>
      <tr>
        <th style="width: 1%" scope="col" class="checkbox-td">
          <input @click="toggleSelectAll" type="checkbox" v-model="allClicked">
        </th>
        <th style="width: 1%" v-show="columns.status" scope="col"></th>
        <th style="width: 5%" v-show="columns.type" scope="col">Type</th>
        <th style="width: 17%" v-show="columns.product" scope="col">Product</th>
        <th style="width: 8%; max-width: 15ch;" v-show="columns.address" scope="col">
          Address
        </th>
        <th style="width: 11%; max-width: 18ch;" v-show="columns.mac" scope="col">MAC</th>
        <th style="width: 11%" v-show="columns.serial_number" scope="col">Serial Number</th>
        <th style="width: 5%" v-show="columns.firmware" scope="col">FW</th>
        <th style="width: 8%" v-show="columns.id" scope="col">ID</th>
        <th style="width: 26%" v-show="columns.location" scope="col">Location</th>
        <th style="width: 8%" v-show="columns.client_asset_id" scope="col">Client Asset ID</th>
        <th style="width: 1%" v-show="columns.credentials" scope="col"></th>
        <th style="width: 1%"></th>
      </tr>
    </thead>
    <tbody>
      <tr :id="node.uuid" :link-id="node.id" :class="{ 'no-creds text-dark': node.credential_count < 1 }"
        v-for="node in nodes" :key="'node-' + node.id" v-once>
        <td class="checkbox-td">
          <input tabindex="-1" class="" type="checkbox">
        </td>
        <td v-show="columns.status" style="vertical-align: middle;">
          <i data-bs-toggle="tooltip" data-bs-placement="top" title="" :data-bs-original-title="node.tooltip" :class="{
            'text-success': node.response_code === 1,
            'text-danger': node.response_code === 0
          }" class='bx bxs-circle'></i>
        </td>
        <td v-show="columns.type">
          <input name="type" autocomplete="off" class="form-control form-control-sm disabled" disabled
            :value="typeFromProduct(node)" type="text" aria-label="default input example">
        </td>
        <td class="editable" :class="{ 'bg-danger bg-gradient': node.error.errorName === 'product_id' }"
          v-show="columns.product" style="min-width: 150px;">
          <!--                      <Multiselect  :name="'product_id'" class="form-control form-control-sm" @change="(event) => checkForChange(event, node, 'product_id')" @clear="(event) => checkForChange(event, node, 'product_id')" @select="(event) => checkForChange(event, node, 'product_id')" v-model="node.product_id" id="nodeProduct" valueProp="id" label="name" :searchable="true" :options="products"></Multiselect>-->
          <input autocomplete="off" list="product-list" name="product_id" class="form-control form-control-sm"
            :value="node.node_model" type="text" aria-label="default input example">
        </td>
        <td class="editable" :class="{ 'bg-danger bg-gradient': node.error.errorName === 'address' }"
          v-show="columns.address" style="max-width: 15ch;">
          <input autocomplete="off" name="address" class="form-control form-control-sm" :value="node.address"
            type="text" aria-label="default input example">
        </td>
        <td class="editable" :class="{ 'bg-danger bg-gradient': node.error.errorName === 'mac' }" v-show="columns.mac"
          style="max-width: 18ch;"><input autocomplete="off" name="mac" class="form-control form-control-sm"
            :value="node.mac" type="text"></td>
        <td class="editable" v-show="columns.serial_number"><input autocomplete="off" name="serial_number"
            class="form-control form-control-sm" :value="node.serial_number" type="text"></td>
        <td class="editable" v-show="columns.firmware"><input autocomplete="off" name="firmware"
            class="form-control form-control-sm" :value="node.firmware" type="text"></td>
        <td class="editable" v-show="columns.id"><input autocomplete="off" name="unit_id"
            class="form-control form-control-sm" :value="node.unit_id" type="text"></td>
        <td class="editable" v-show="columns.location"><input autocomplete="off" name="details"
            class="form-control form-control-sm" :value="node.details" type="text"></td>
        <td class="editable" v-show="columns.client_asset_id"><input autocomplete="off" name="client_asset_id"
            class="form-control form-control-sm" :value="node.client_asset_id" type="text"></td>
        <td v-show="columns.address">
          <button type="button" tabindex="-1" class="btn btn-sm btn-primary openAddress" title="Open Address"
            @click="openAddressLink(node.address)"><i class="bx bx-link me-0"></i></button>
        </td>
        <td v-show="columns.credentials">
          <button type="button" tabindex="-1" class="btn btn-sm btn-light showCredentials mx-1"
            title="Manage Credentials" data-bs-toggle="modal" data-bs-target="#manageCredentialsModal"><i
              class="bx bx-key me-0"></i></button>
        </td>
        <td>
          <button v-if="$hasRole('Support')" type="button" tabindex="-1" class="btn btn-sm btn-danger deleteButton mx-1"
            title="Delete Node"><i class="bx bx-trash me-0"></i></button>
        </td>
      </tr>
      <tr :id="templateUuid" class="brandNewRow">
        <td class="checkbox-td">
          <input tabindex="-1" class="" type="checkbox">
        </td>
        <td link-col="status" style="vertical-align: middle;">
          <i data-bs-toggle="tooltip" class='bx bxs-circle'></i>
        </td>
        <td link-col="type">
          <input name="type" autocomplete="off" class="form-control form-control-sm disabled" disabled type="text"
            aria-label="default input example">
        </td>
        <td link-col="product_id" class="editable" style="min-width: 150px;">
          <input list="product-list" autocomplete="off" name="product_id" class="form-control form-control-sm"
            type="text" aria-label="default input example">
        </td>
        <td link-col="address" class="editable" style="max-width: 15ch; position: relative;">
          <input autocomplete="off" name="address" class="form-control form-control-sm" type="text"
            aria-label="default input example">
        </td>
        <td link-col="mac" class="editable" style="max-width: 18ch;"><input autocomplete="off" name="mac"
            class="form-control form-control-sm" type="text"></td>
        <td link-col="serial_number" class="editable"><input autocomplete="off" name="serial_number"
            class="form-control form-control-sm" type="text"></td>
        <td link-col="firmware" class="editable"><input autocomplete="off" name="firmware"
            class="form-control form-control-sm" type="text"></td>
        <td link-col="id" class="editable"><input autocomplete="off" name="unit_id" class="form-control form-control-sm"
            type="text"></td>
        <td link-col="location" class="editable"><input autocomplete="off" name="details"
            class="form-control form-control-sm" type="text"></td>
        <td link-col="client_asset_id" class="editable"><input autocomplete="off" name="client_asset_id"
            class="form-control form-control-sm" type="text"></td>
        <td link-col="credentials">
          <!--          <button type="button" tabindex="-1" class="btn btn-sm btn-light showCredentials" title="Manage Credentials" data-bs-toggle="modal" data-bs-target="#manageCredentialsModal"><i class="bx bx-key me-0"></i></button>-->
        </td>
        <td>
          <button v-if="$hasRole('Support')" type="button" tabindex="-1" class="btn btn-sm btn-danger deleteButton mx-1"
            title="Delete Node"><i class="bx bx-trash me-0"></i></button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- MODALS -->

  <!-- Manage Credentials -->
  <div class="modal fade" id="manageCredentialsModal" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage Device Credentials</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-if="currentDevice">{{ currentDevice.address }} <span
              v-if="currentDevice.unit_id">({{ currentDevice.unit_id }})</span></p>
          <table class="table mb-0 table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Username</th>
                <th scope="col">Password</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="credential in notDeletedCredentials" :key="'credential-' + credential.id">
                <td><input class="form-control" autocomplete="off" v-model="credential.name" type="text"></td>
                <td><input class="form-control" autocomplete="off" v-model="credential.username" type="text"></td>
                <td><input class="form-control" autocomplete="off" v-model="credential.password" type="text"></td>
                <td>
                  <button type="button" class="btn btn-danger" title="Delete Credentials"
                    @click="deleteCredential(credential)"><i class="bx bx-trash me-0"></i></button>
                </td>
              </tr>
              <tr v-if="credentials.length < 1">
                <td colspan="4">No Credentials for this Device</td>
              </tr>
              <tr>
                <td style="border-bottom: none;" colspan="3"></td>
                <td style="border-bottom: none;"><button type="button" class="btn btn-success"
                    @click="credentials.push({ name: '', username: '', password: '' })"><i
                      class="bx bx-plus me-0"></i></button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeManageCredentialsModal'>Close</button>
          <submit-button type="button" :background="'btn btn-primary'" :clicked="updatingCredentials"
            @click="updateCredentials" text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Manage Bulk Credentials -->
  <div class="modal fade" id="manageBulkCredentialsModal" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage Bulk Device Credentials</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-danger"><span style="font-weight: bold;">WARNING: </span>This will add credentials to all
            selected nodes <strong>which have been saved</strong>. You will have to individually remove these
            credentials once added.</p>
          <table class="table mb-0 table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Username</th>
                <th scope="col">Password</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="credential in bulkCredentials" :key="'credential-' + credential.id">
                <td><input class="form-control" autocomplete="off" v-model="credential.name" type="text"></td>
                <td><input class="form-control" autocomplete="off" v-model="credential.username" type="text"></td>
                <td><input class="form-control" autocomplete="off" v-model="credential.password" type="text"></td>
                <td>
                  <button type="button" class="btn btn-danger" title="Delete Credentials"
                    @click="deleteBulkCredential(credential)"><i class="bx bx-trash me-0"></i></button>
                </td>
              </tr>
              <tr v-if="bulkCredentials.length < 1">
                <td colspan="4">No Credentials</td>
              </tr>
              <tr>
                <td style="border-bottom: none;" colspan="3"></td>
                <td style="border-bottom: none;"><button type="button" class="btn btn-success"
                    @click="bulkCredentials.push({ uuid: getUUID(), name: '', username: '', password: '' })"><i
                      class="bx bx-plus me-0"></i></button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeManageBulkCredentialsModal'>Close</button>
          <submit-button type="button" :background="'btn btn-primary'" :clicked="updatingBulk"
            @click="updateBulkCredentials" text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Manage Columns -->
  <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage Columns</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Please note these settings will be saved locally in your browser. If you clear your browser settings these
            settings may reset.</p>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="statusColumn" @change="updateColumnSettings"
              v-model="columns.status">
            <label class="form-check-label" for="statusColumn">Status</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="productColumn" @change="updateColumnSettings"
              v-model="columns.product">
            <label class="form-check-label" for="productColumn">Product</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="addressColumn" @change="updateColumnSettings"
              v-model="columns.address">
            <label class="form-check-label" for="addressColumn">Address</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="typeColumn" @change="updateColumnSettings"
              v-model="columns.type">
            <label class="form-check-label" for="typeColumn">Type</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="macColumn" @change="updateColumnSettings"
              v-model="columns.mac">
            <label class="form-check-label" for="macColumn">MAC</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="serialNumberColumn" @change="updateColumnSettings"
              v-model="columns.serial_number">
            <label class="form-check-label" for="serialNumberColumn">Serial Number</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="firmwareColumn" @change="updateColumnSettings"
              v-model="columns.firmware">
            <label class="form-check-label" for="firmwareColumn">Firmware</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="idColumn" @change="updateColumnSettings"
              v-model="columns.id">
            <label class="form-check-label" for="idColumn">Unit ID</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="locationColumn" @change="updateColumnSettings"
              v-model="columns.location">
            <label class="form-check-label" for="locationColumn">Location</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="clientAssetID" @change="updateColumnSettings"
              v-model="columns.client_asset_id">
            <label class="form-check-label" for="clientAssetID">Client Asset ID</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="credentialsColumn" @change="updateColumnSettings"
              v-model="columns.credentials">
            <label class="form-check-label" for="credentialsColumn">Credentials</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeManageColumnsModal'>Close</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Manage Model for Selected -->
  <div class="modal fade" id="manageModelForSelected" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Set Product for Selected</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Please note this will override the currently selected Product for all selected nodes.</p>
          <select v-model="bulk.product_id" class="form-select">
            <option v-for="product in products" :key="'product-' + product.id" :value="product.id">{{ product.name }}
            </option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeManageModelForSelected'>Close</button>
          <submit-button type="button" :background="'btn btn-primary'" @click="updateBulkProduct"
            text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Manage Headers for CSV File -->
  <div class="modal fade" id="manageHeadersForFile" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Configure Field Matching</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Please check and confirm that the columns in the file have been properly matched to fields in Link</p>
          <div class="row">
            <div class="col-6">
              <p style="font-weight: bold">File Value</p>
            </div>
            <div class="col-6">
              <p style="font-weight: bold">Link Value</p>
            </div>
          </div>
          <div v-for="header in fileHeaders" :key="'header-' + header.name" class="row">
            <div class="col-6">
              <p style="font-weight: bold;">{{ header.name }}</p>
            </div>
            <div class="col-6">
              <select v-model="header.match" class="form-select">
                <option :value="'IGNORE'">IGNORE</option>
                <option :value="'product_id'">Product</option>
                <option :value="'address'">IP Address</option>
                <option :value="'mac'">MAC</option>
                <option :value="'serial_number'">Serial Number</option>
                <option :value="'firmware'">Firmware</option>
                <option :value="'unit_id'">Unit ID</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeManageHeadersForFileModal'>Close</button>
          <submit-button type="button" :background="'btn btn-primary'" @click="confirmFileMatches"
            text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Migrate Selected Nodes -->
  <div class="modal fade" id="migrateNodeModal" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Migrate Selected Nodes</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="tx-danger">This action will move the selected devices to another site. Please make sure this is the
            action you wish to perform!</p>
          <Multiselect v-model="nodeMigration.site_id" id="nodeMigrationSite" valueProp="id" label="name"
            :searchable="true" :options="sites"></Multiselect>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeMigrateNodeModal'>Close</button>
          <submit-button type="button" :background="'btn btn-primary'" :clicked="nodeMigration.processing"
            @click="migrateNodes" text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Import Config Modal -->
  <div class="modal fade" id="importConfigModal" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Import Config</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-danger" v-if="site.range_and_subnet === null || site.range_and_subnet === undefined">The IP
            Range and Subnet must be set before you can import a config!</p>
          <p class="text-danger">This will copy the config from the selected site to this site.</p>
          <Multiselect v-if="!(site.range_and_subnet === null || site.range_and_subnet === undefined)"
            v-model="configCopy.site_id" id="nodeMigrationSite" valueProp="id" label="name" :searchable="true"
            :options="sites"></Multiselect>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            id='closeMigrateNodeModal'>Close</button>
          <submit-button :disabled="site.range_and_subnet === null || site.range_and_subnet === undefined" type="button"
            :background="'btn btn-primary'" :clicked="configCopy.processing" @click="copyConfig"
            text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global $ */
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
import SubmitButton from "../../../components/SubmitButton";
import Multiselect from '@vueform/multiselect'
import { Netmask } from 'netmask';

export default {
  name: "SiteNodes",
  components: { SubmitButton, Multiselect },
  props: {
    site: Object
  },
  data() {
    return {
      allClicked: false,
      products: [],
      nodes: [],
      loaded: false,
      templateRow: null,
      editableContainers: [],
      pendingUpdates: {},
      originalValues: {},
      templateUuid: 'new_' + uuidv4(),
      updating: false,
      updatingBulk: false,
      columns: {
        status: true,
        product: true,
        address: true,
        type: true,
        mac: true,
        firmware: true,
        serial_number: true,
        id: true,
        location: true,
        credentials: true,
        client_asset_id: true
      },
      updatingCredentials: false,
      currentDevice: null,
      credentials: [],
      bulk: {
        product_id: null
      },
      fileHeaders: [],
      fileData: {},
      bulkCredentials: [],
      config: {
        downloading: false
      },
      sites: [],
      nodeMigration: {
        site_id: null,
        processing: false
      },
      configCopy: {
        site_id: null,
        processing: false
      },
      dragFill: {
        startX: null,
        startY: null,
        endX: null,
        endY: null,
      }
    }
  },
  mounted() {
    this.loadProducts();
    this.loadSites();
    document.addEventListener('keydown', this.handleSave);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleSave)
  },
  methods: {
    handleSave(e) {
      if (e.ctrlKey && e.key === 's') {
        // Prevent the Save dialog to open
        e.preventDefault();
        // Place your code here
        this.savePendingUpdates();
      }
    },
    openAddressLink(address) {
      window.open(`http://${address}`, '_blank');
    },
    loadSites() {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/list`)
        .then(response => {
          this.sites = response.data.sites;
        })
        .catch(error => {
          this.$error('Failed to load sites.', error);
        });
    },
    loadNodes() {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/nodes`)
        .then(response => {
          this.nodes = response.data.nodes.sort((a, b) => {
            // let aSplit = a.address.split('.');
            // let aValue = 0;
            // let bSplit = b.address.split('.');
            // let bValue = 0;

            // aValue += parseInt(aSplit[0] || 0) * 256^4
            // aValue += parseInt(aSplit[1] || 0) * 256^3
            // aValue += parseInt(aSplit[2] || 0) * 256^2
            // aValue += parseInt(aSplit[3] || 0) * 256

            // bValue += parseInt(bSplit[0] || 0) * 256^4
            // bValue += parseInt(bSplit[1] || 0) * 256^3
            // bValue += parseInt(bSplit[2] || 0) * 256^2
            // bValue += parseInt(bSplit[3] || 0) * 256

            // if(aValue < bValue){
            //   return -1;
            // }
            // if(aValue > bValue){
            //   return 1;
            // }
            // return 0;
            let octetsA = a.address.split('.');
            let octetsB = b.address.split('.');

            if (a.address == b.address)
              return 0
            else if (a.address == '')
              return -1
            else if (b.address == '')
              return 1

            if (octetsA == octetsB)
              return 0
            else if (parseInt(octetsA[0]) > parseInt(octetsB[0]))
              return 1
            else if (parseInt(octetsA[0]) < parseInt(octetsB[0]))
              return -1
            else if (parseInt(octetsA[1]) > parseInt(octetsB[1]))
              return 1
            else if (parseInt(octetsA[1]) < parseInt(octetsB[1]))
              return -1
            else if (parseInt(octetsA[2]) > parseInt(octetsB[2]))
              return 1
            else if (parseInt(octetsA[2]) < parseInt(octetsB[2]))
              return -1
            else if (parseInt(octetsA[3]) > parseInt(octetsB[3]))
              return 1
            else if (parseInt(octetsA[3]) < parseInt(octetsB[3]))
              return -1
          });
          this.nodes.forEach((n) => {
            n.checked = false
            n.errored = false
            n.uuid = uuidv4()
            n.error = { message: "", errorName: "" }
            let tooltip = ``;
            if (n.last_ping) {
              n.last_ping = DateTime.fromSeconds(n.last_ping).toFormat('dd/MM/yyyy HH:mm:ss');
              tooltip += `Last Ping: ${n.last_ping}`;
            }
            if (n.last_successful_ping) {
              n.last_successful_ping = DateTime.fromSeconds(n.last_successful_ping).toFormat('dd/MM/yyyy HH:mm:ss');
              tooltip += `\nLast Successful Ping: ${n.last_successful_ping}`;
            }
            if (tooltip.length < 1) {
              tooltip = 'Device has not been pinged.';
            }
            n.tooltip = tooltip;
          });
          // this.originalNodes = JSON.parse(JSON.stringify(this.nodes));
          // this.addNode();
          this.loaded = true;
          this.$nextTick(() => {
            $('[data-bs-toggle="tooltip"]').tooltip();
            this.prepareInteractiveMode();
          })
        })
        .catch(error => {
          this.$error("Failed to load site devices.", error);
        })
    },
    copyConfig() {
      this.configCopy.processing = true;
      axios.post(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/copy-config`, {
        site_id: this.configCopy.site_id
      })
        .then(() => {
          this.configCopy.processing = false;
          $('#importConfigModal').modal('hide');
          this.$success('Successfully copied configuration.');
          this.$router.go(0);
        })
        .catch(error => {
          this.configCopy.processing = false;
          this.$error('Failed to copy configuration.', error);
        });
    },
    loadProducts() {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/product/list`)
        .then(response => {
          this.products = response.data.products;
          this.loadNodes();
        })
        .catch(error => {
          this.$error("Failed to load products.", error);
        })
    },
    typeFromProduct(node) {
      let product = this.products.filter((p) => {
        return p.id === node.product_id;
      })[0];
      if (product) {
        return product.type
      }
      return "";
    },
    duplicateSelectedRow() {
      let selectedInputs = $('#siteNodesTable tbody input[type="checkbox"]:checked');
      if (selectedInputs.length === 0) {
        this.$error("No row selected to duplicate.");
        return;
      }

      // Clone the first selected row
      let row = selectedInputs.first().parents('tr');

      // Fields that need to be copied
      const fieldsToCopy = [
        'product_id',
        'address',
        'mac',
        'serial_number',
        'firmware',
        'unit_id',
        'details',
        'client_asset_id'
      ];

      // Extract values from the selected row
      let copiedDetails = {};
      fieldsToCopy.forEach(field => {
        let input = row.find(`input[name='${field}']`);
        copiedDetails[field] = input.length > 0 ? input.val() : undefined;
      });

      // Validate that required fields like product_id are available
      if (!copiedDetails.product_id) {
        this.$error("Cannot duplicate: Required fields (product_id) is missing.");
        return;
      }

      // Resolve product_id to an integer
      let resolvedProduct = this.products.filter((p) => {
        return p.name === copiedDetails.product_id; // Match the product name
      })[0];

      let product_id_integer = null;

      if (resolvedProduct) {
        product_id_integer = resolvedProduct.id; // Set to the resolved product ID
      } else {
        this.$error(`Cannot duplicate: Product "${copiedDetails.product_id}" not found.`);
        return;
      }

      // Ask the user for the number of duplicates
      let numberOfDuplicates = parseInt(prompt("Enter the number of duplicates:", "1"), 10);
      if (isNaN(numberOfDuplicates) || numberOfDuplicates <= 0) {
        this.$error("Invalid number of duplicates.");
        return;
      }

      for (let i = 0; i < numberOfDuplicates; i++) {
        // Create a new row and assign a unique ID
        let newRow = row.clone(true, true);
        let newId = 'new_' + uuidv4();
        newRow.attr('id', newId);
        newRow.removeAttr('link-id'); // Remove any existing link-id

        // Hide the manage credential and open address buttons
        newRow.find('.showCredentials').hide();
        newRow.find('.openAddress').hide();

        // Uncheck the checkbox in the new row
        newRow.find('input[type="checkbox"]').prop('checked', false);

        // Update fields in the new row
        newRow.find('input').each((index, element) => {
          let fieldName = $(element).attr('name');
          if (fieldName && Object.prototype.hasOwnProperty.call(copiedDetails, fieldName)) {
            $(element).val(copiedDetails[fieldName]); // Set value from the copied details
          } else {
            $(element).val(''); // Clear fields that are not in fieldsToCopy
          }
        });

        // Add the new row to the table
        $('#siteNodesTable tbody').append(newRow);

        // Prepare the new data for pendingUpdates
        let pendingUpdate = {
          uuid: newId,
          id: undefined, // Undefined for new rows
          product_id: product_id_integer
        };

        if (copiedDetails.address) pendingUpdate.address = copiedDetails.address;
        if (copiedDetails.details) pendingUpdate.details = copiedDetails.details;
        if (copiedDetails.mac) pendingUpdate.mac = copiedDetails.mac;
        if (copiedDetails.serial_number) pendingUpdate.serial_number = copiedDetails.serial_number;
        if (copiedDetails.firmware) pendingUpdate.firmware = copiedDetails.firmware;
        if (copiedDetails.unit_id) pendingUpdate.unit_id = copiedDetails.unit_id;
        if (copiedDetails.client_asset_id) pendingUpdate.client_asset_id = copiedDetails.client_asset_id;

        this.pendingUpdates[newId] = pendingUpdate;
      }
    },
    prepareInteractiveMode() {

      // I've done this in a bit of a janky way...
      // Vue JS renders the existing devices for us and by the time this function is called
      // we will be ready to take over from Vue and handle the rows in native JS.

      const nodeTable = $('#siteNodesTable').first();

      this.editableContainers = nodeTable.find('.editable');
      this.deleteButtons = nodeTable.find('.deleteButton');
      this.showCredentialsButtons = nodeTable.find('.showCredentials');
      this.pendingUpdates = {};

      this.editableContainers.each((index, element) => {

        let container = $(element);

        container.find('input').on('change keyup init', (e) => {

          let row = $(e.target).parents('tr');
          let input = $(e.target);
          // let currentContainer = row.parents('.editable');

          if (e.key === 'Enter') {
            let next = row.nextAll().first();
            if (next.length > 0) {
              let inputName = input.attr('name');
              let nextInput = next.find("input[name='" + inputName + "']");
              if (nextInput.length > 0) {
                nextInput.focus().select();
              }
            }
          }

          let id = row.attr('id');
          // let linkId = row.attr('link-id');
          let name = input.attr('name');

          if (name === 'product') {
            let product = this.products.filter((p) => {
              return p.name === input.val();
            })[0];
            if (product) {
              row.find("input[name='type']").val(product.type);
            } else {
              row.find("input[name='type']").val('');
            }
          }

          if (Object.prototype.hasOwnProperty.call(this.pendingUpdates, id) === false) {
            this.pendingUpdates[id] = {};
          }

          if (Object.prototype.hasOwnProperty.call(this.originalValues, id) === false) {
            this.originalValues[id] = {};
          }

          if (Object.prototype.hasOwnProperty.call(this.originalValues[id], name) === false) {
            if (e.type === 'init') {
              this.originalValues[id][name] = input.val();
            } else {
              this.originalValues[id][name] = '';
            }
          }

          let newValue = $(e.target).val();

          if (name === 'product_id') {
            let product = this.products.filter((p) => {
              return p.name === input.val();
            })[0];
            if (product) {
              newValue = product.id;
              console.log("Setting product_id to", newValue);
              this.pendingUpdates[id]['product_id'] = newValue;
              if (e.type === 'init') {
                this.originalValues[id]['product_id'] = newValue;
              }
            } else {
              delete this.pendingUpdates[id]['product_id'];
            }
          }

          let originalValue = this.originalValues[id][name];

          if (newValue !== originalValue) {

            if (row.hasClass('brandNewRow') === true) {
              let brandNewRow = row.clone(true, true);
              row.removeClass('brandNewRow');
              nodeTable.find('tbody').append(brandNewRow);
              brandNewRow.attr('id', 'new_' + uuidv4());
              brandNewRow.find('input').each((index, element) => {
                $(element).val('');
              })
            }

            if (name !== 'product_id') {
              if (name === 'mac') {
                this.pendingUpdates[id][name] = input.val().toUpperCase();
                input.val(input.val().toUpperCase());
              } else {
                this.pendingUpdates[id][name] = input.val();
              }
            }

            // Run validation on the new value
            this.runValidation(id, name, newValue);

          } else {
            delete this.pendingUpdates[id][name];
            // Run validation on the new value
            this.runValidation(id, name, newValue);
          }

          let changesCount = Object.getOwnPropertyNames(this.pendingUpdates[id]).length;

          if (changesCount > 0) {
            row.css({
              'background-color': 'hsla(120, 100%, 95%, 0.4)'
            })
          } else {
            row.css({
              'background-color': ''
            })
          }

        })
          .trigger('init').on('click', (e) => {
            $(e.target).select();
          }).on('keydown', (e) => {

            let row = $(e.target).parents('tr');
            let input = $(e.target);
            let inputElement = input.get(0);
            let selectionStart = inputElement.selectionStart;
            let selectionEnd = inputElement.selectionEnd;
            let length = input.val().length;

            if (e.key === 'Escape') {
              input.focus().select()
              return false;
            }

            if (length === selectionEnd && selectionStart === 0) {

              // console.log(e);

              if (e.key === 'ArrowDown') {
                if (e.shiftKey) {
                  let next = row.nextAll().first();
                  if (next.length > 0) {
                    let inputName = input.attr('name');
                    let nextInput = next.find("input[name='" + inputName + "']");
                    if (nextInput.length > 0) {
                      let value = inputElement.value;
                      if (inputName == 'address') {
                        let split = value.split('.');
                        let lastOctet = parseInt(split[3]);
                        lastOctet += 1;
                        split[3] = lastOctet;
                        value = split.join('.');
                      }
                      if (inputName == 'unit_id') {
                        let split = value.split(' ');
                        let lastPart = split[split.length - 1];
                        let lastPartInt = parseInt(lastPart);
                        if (!isNaN(lastPartInt)) {
                          lastPartInt += 1;
                          split[split.length - 1] = lastPartInt;
                          value = split.join(' ');
                        }
                      }
                      nextInput.get(0).value = value;
                      nextInput.focus().select();
                    }
                  }
                  return false;
                }
                let next = row.nextAll().first()
                if (next.length > 0) {
                  let inputName = input.attr('name');
                  let nextInput = next.find("input[name='" + inputName + "']");
                  if (nextInput.length > 0) {
                    nextInput.focus().select();
                  }
                }
                return false
              }

              if (e.key === 'ArrowUp') {
                let next = row.prevAll().first();
                if (next.length > 0) {
                  let inputName = input.attr('name');
                  let nextInput = next.find("input[name='" + inputName + "']");
                  if (nextInput.length > 0) {
                    nextInput.focus().select();
                  }
                }
                return false;
              }

              if (e.key === 'ArrowRight') {
                let cell = input.parents('td');
                let next = cell.nextAll().first();
                if (next.length > 0) {
                  let nextInput = next.find('input');
                  if (nextInput.length > 0) {
                    nextInput.focus().select();
                  }
                }
                return false;
              }

              if (e.key === 'ArrowLeft') {
                let cell = input.parents('td');
                let next = cell.prevAll().first();

                if (next.length > 0) {
                  let nextInput = next.find("input");
                  if (nextInput.length > 0) {
                    nextInput.focus().select();
                  }
                }
                return false;
              }

            }

            return true;

          })

      });

      this.deleteButtons.each((index, element) => {
        $(element).on('click', (e) => {
          let row = $(e.target).parents('tr');
          let nodeId = row.attr('id');
          let linkId = row.attr('link-id');
          if (linkId === undefined) {
            delete this.pendingUpdates[nodeId];
            nodeTable.find('tbody').get(0).removeChild(row.get(0));
          } else {
            if (Object.prototype.hasOwnProperty.call(this.pendingUpdates, nodeId) === false) {
              this.pendingUpdates[nodeId] = {};
            }
            row.addClass('hidden');
            this.pendingUpdates[nodeId]['DELETE'] = true;
          }
        });
      })

      this.showCredentialsButtons.each((index, element) => {
        $(element).on('click', (e) => {
          let row = $(e.target).parents('tr');
          let nodeId = row.attr('id');
          let linkId = row.attr('link-id');
          console.log("Loading credentials");
          this.loadCredentials({ id: linkId, uuid: nodeId });
        });
      })

    },
    runValidation(id, name, value){

      // Validation Rules:
      // - IP Address format
      // const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
      // - IP Address matches subnet
      // Netmask library
      // - MAC Address format
      // const regexExp = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
      // - Product exists (gonna be harder than the others)
      // - 

      if(name === 'address' && value.length > 0){
        const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        if(!regexExp.test(value)){
          let erroredNode = $(`#${id}`);
          erroredNode.addClass('bg-danger').attr('title', "Invalid IP Address");
        }else{
          let erroredNode = $(`#${id}`);
          erroredNode.removeClass('bg-danger').removeAttr('title');
        }

        if(this.site.range_and_subnet){
          let range = new Netmask(this.site.range_and_subnet);
          if(!range.contains(value)){
            let erroredNode = $(`#${id}`);
            erroredNode.addClass('bg-danger').attr('title', "IP Address not in subnet");
          }else{
            let erroredNode = $(`#${id}`);
            erroredNode.removeClass('bg-danger').removeAttr('title');
          }
        }
      } else if (name === 'address' && value.length < 1){
        let erroredNode = $(`#${id}`);
        erroredNode.removeClass('bg-danger').removeAttr('title');
      }

      if(name === 'mac' && value.length > 0){
        const regexExp = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
        if(!regexExp.test(value)){
          let erroredNode = $(`#${id}`);
          erroredNode.addClass('bg-danger').attr('title', "Invalid MAC Address");
        }else{
          let erroredNode = $(`#${id}`);
          erroredNode.removeClass('bg-danger').removeAttr('title');
        }
      } else if (name === 'mac' && value.length < 1){
        let erroredNode = $(`#${id}`);
        erroredNode.removeClass('bg-danger').removeAttr('title');
      }

      if(name === 'product' && value.length > 0){
        // Product
        if(!this.products.filter((p) => { return p.name === value }).length > 0){
          let erroredNode = $(`#${id}`);
          erroredNode.addClass('bg-danger').attr('title', "Product does not exist");
        }else{
          let erroredNode = $(`#${id}`);
          erroredNode.removeClass('bg-danger').removeAttr('title');
        }
      } else if (name === 'product' && value.length < 1){
        let erroredNode = $(`#${id}`);
        erroredNode.removeClass('bg-danger').removeAttr('title');
      }

      console.log(`Validating ${name} with value ${value} (ID: ${id})`);
    },
    savePendingUpdates() {
      /**
       * In order to reduce work we only want to apply actual changes and not simply overwrite the old record
       * with the new one.
       *
       * this.pendingUpdates contains an object of changes made by the user which we can use to inform the API
       */

      let payload = [];
      let props = Object.getOwnPropertyNames(this.pendingUpdates);

      for (let p in props) {
        let key = props[p];
        let length = Object.getOwnPropertyNames(this.pendingUpdates[key]).length;

        if (length > 0) {
          let row = $(`#${key}`);
          let linkId = row.attr('link-id');
          let obj = { uuid: key, id: linkId, ...this.pendingUpdates[key] }
          payload.push(obj);
        } else {
          delete this.pendingUpdates[key];
        }
      }

      axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/nodes`, {
        data: JSON.stringify(payload)
      })
        .then(response => {
          let errors = response.data.errors;
          let success = response.data.success;
          if (success.length > 0 && errors.length > 0) {
            this.$warning("Saved changes with errors");
          }
          if (success.length < 1 && errors.length > 0) {
            this.$error("Failed to save changes");
          }
          if (success.length > 0 && errors.length < 1) {
            this.$success("Saved changes!");
          }
          if (errors.length > 0) {
            errors.forEach((error) => {
              // locate node by element id (uuid)
              // set class to has-errors
              // set title to error message
              let erroredNode = $(`#${error.node.uuid}`);
              erroredNode.addClass('bg-danger').attr('title', error.message);
            });
          }
          success.forEach((s) => {
            let node = $(`#${s.node.uuid}`);
            node.removeClass('has-errors').removeAttr('title');
            if (s.node.id !== node.id) {
              node.attr('link-id', s.node.id);
            }
            delete this.pendingUpdates[node.uuid];
            delete this.originalValues[node.uuid];
            this.pendingUpdates[node.uuid] = {};
          });
          this.$success("Saved Changes!");
        })
        .catch(error => {
          this.$error("Failed to save changes", error);
        })
    },
    loadCredentials(node) {
      this.currentDevice = node;
      if (this.currentDevice.id) {
        axios.get(`${process.env.VUE_APP_API_URL}/v1/nodes/${this.currentDevice.id}/credentials`)
          .then(response => {
            this.credentials = response.data.credentials;
          })
          .catch(error => {
            this.$error("Failed to load device credentials.", error);
          })
      } else {
        this.credentials = [];
      }
    },
    deleteCredential(credential) {
      credential.delete = true;
      this.currentDevice.credential_count--;
    },
    updateCredentials() {
      this.updatingCredentials = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/nodes/${this.currentDevice.id}/credentials`, {
        data: JSON.stringify(this.credentials)
      })
        .then(response => {
          if (response.data.success) {
            this.currentDevice.credential_count = this.credentials.length;
            this.$success("Updated credentials for node");
          }
          $('#closeManageCredentialsModal').click();
        })
        .catch(error => {
          this.$error("Failed to update credentials for node", error);
        })
        .finally(() => {
          this.updatingCredentials = false;
        })
    },
    deleteBulkCredential(credential) {
      this.bulkCredentials = this.bulkCredentials.filter((bc) => {
        return bc.uuid !== credential.uuid;
      });
    },
    updateBulkCredentials() {
      this.updatingBulk = true;
      let selectedNodes = [];
      let selectedInputs = $('input:checked');
      selectedInputs.each((index, element) => {
        let row = $(element).parents('tr');
        let nodeId = row.attr('id');
        let linkId = row.attr('link-id');
        selectedNodes.push({ id: linkId, uuid: nodeId });
      })
      let nodeIds = [];
      selectedNodes.forEach((n) => {
        nodeIds.push(n.id);
      })
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/nodes/bulk`, {
        id: this.site.id,
        data: JSON.stringify(this.bulkCredentials),
        nodes: nodeIds
      })
        .then(response => {
          if (response.data.success) {
            this.$success("Added credential to multiple nodes");
          }
          $('#closeManageBulkCredentialsModal').click();
        })
        .catch(error => {
          this.$error("Failed to add credential to nodes.", error);
        })
        .finally(() => {
          this.updatingBulk = false;
        });
    },
    updateColumnSettings() {
      localStorage.setItem('link::site::columns', JSON.stringify(this.columns));

      Object.keys(this.columns).forEach((key) => {

        console.log(key, this.columns[key], $("td[link-col='" + key + "'").length);

        $("td[link-col='" + key + "'").removeClass('hidden');
        if (!this.columns[key]) {
          $("td[link-col='" + key + "'").addClass('hidden');
        }

      });

    },
    loadColumnSettings() {
      let savedColumns = localStorage.getItem('link::site::columns');
      if (savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== '') {
        console.log('Updating columns');
        this.columns = JSON.parse(savedColumns);
      }
    },
    toggleSelectAll() {
      this.allClicked = !this.allClicked;
      let selectedInputs = $('input[type="checkbox"]');
      selectedInputs.each((index, element) => {
        $(element).prop('checked', this.allClicked);
      })
    },
    updateBulkProduct() {
      let product = this.products.filter((p) => {
        return p.id === this.bulk.product_id;
      })[0];
      let selectedInputs = $('input:checked');
      selectedInputs.each((index, element) => {
        let row = $(element).parents('tr');
        let productInput = row.find("input[name='product']");
        productInput.val(product.name);
        productInput.trigger('change');
      })
      $('#closeManageModelForSelected').click();
    },
    deleteSelected() {
      Swal.fire({
        title: 'Are you sure?',
        text: "It looks like you're trying to delete a lot of data, are you sure you want to do this?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          let selectedInputs = $('input:checked');
          selectedInputs.each((index, element) => {
            let row = $(element).parents('tr');
            if (row.hasClass('brandNewRow') !== true) {
              let nodeId = row.attr('id');
              let linkId = row.attr('link-id');
              if (nodeId !== undefined) {
                if (linkId === undefined) {
                  delete this.pendingUpdates[nodeId];
                  $('#siteNodesTable').first().find('tbody').get(0).removeChild(row.get(0));
                } else {
                  if (Object.prototype.hasOwnProperty.call(this.pendingUpdates, nodeId) === false) {
                    this.pendingUpdates[nodeId] = {};
                  }
                  this.pendingUpdates[nodeId]['DELETE'] = true;
                  row.addClass('hidden');
                }
              }
            }
          });

          Swal.fire(
            'Deleted!',
            'All selected products have been marked for deletion. Once you save your changes this cannot be reverted!',
            'success'
          )
        }
      })
    },
    selectFile() {
      let file = this.$refs.configfile.files[0];
      if (!file) return;

      let data = new FormData();
      data.append('file', file, file.name);
      axios.post(`${process.env.VUE_APP_API_URL}/v1/utils/convert_to_json`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          /**
           * Determine if we already have a match for all the returned headers
           * If we do, go ahead and add all the nodes to the config
           * If we don't, we need to ask the user to match the headers to the fields for us
           * and then save that for use later.
           */
          if (response.data.headers.length < 1 || response.data.json.length < 1) return;
          this.fileData = response.data.json;
          this.fileHeaders = [];
          let headerDictionary = localStorage.getItem('link::site::headerdict');
          if (headerDictionary !== undefined && headerDictionary !== null && headerDictionary !== 'null' && headerDictionary !== '') {
            headerDictionary = JSON.parse(headerDictionary) || {};
          } else {
            headerDictionary = {};
          }
          response.data.headers.forEach((header) => {
            let match = "IGNORE";
            if (Object.prototype.hasOwnProperty.call(headerDictionary, header)) {
              match = headerDictionary[header];
            }
            this.fileHeaders.push({ name: header, match: match });
          })

          this.$refs.configfile.value = "";

          $('#manageHeadersForFile').modal('toggle');
        })
        .catch(error => {
          this.$error("Failed to parse uploaded file!", error);
        })
    },
    confirmFileMatches() {
      $('#closeManageHeadersForFileModal').click();
      let headerDictionary = localStorage.getItem('link::site::headerdict');
      if (headerDictionary !== undefined && headerDictionary !== null && headerDictionary !== 'null' && headerDictionary !== '') {
        headerDictionary = JSON.parse(headerDictionary) || {};
      } else {
        headerDictionary = {};
      }

      this.fileHeaders.forEach((header) => {
        headerDictionary[header.name] = header.match;
      });

      localStorage.setItem('link::site::headerdict', JSON.stringify(headerDictionary));

      let matchedFields = this.fileHeaders.filter((fh) => {
        return fh.match !== 'IGNORE';
      }).length;
      if (matchedFields < 1) {
        return;
      }

      let count = 0;
      this.fileData.forEach((data) => {

        let newNode = { uuid: uuidv4(), changed: true, error: { message: "", errorName: "" }, checked: false, errored: false, product_id: null, address: null, mac: null, serial_number: null, firmware: null, unit_id: null, details: null, credentials: [] }

        this.fileHeaders.forEach((fh) => {
          if (fh.match !== 'IGNORE') {
            newNode[fh.match] = data[fh.name];
          }
        })

        if (newNode.product_id !== null) {

          // Convert text model to product ID
          let productSearch = this.products.filter((p) => {
            return p.name === newNode.product_id;
          })[0];

          if (productSearch) {

            let tableRows = $('#siteNodesTable').first().find('tbody tr');

            tableRows.each((index, element) => {
              let row = $(element);
              let rowModel = row.find("input[name=product_id]").val().trim().toLowerCase();
              let rowAddress = row.find("input[name=address]").val().trim().toLowerCase();

              let payloadModel = productSearch.name.trim().toLowerCase();
              let payloadAddress = newNode.address.trim().toLowerCase();

              if (rowModel === payloadModel && rowAddress === payloadAddress) {
                row.find("input[name=mac]").val(newNode.mac).trigger('change');
                row.find("input[name=serial_number]").val(newNode.serial_number).trigger('change');
                row.find("input[name=firmware]").val(newNode.firmware).trigger('change');
              }
            })

          }

        }

      });

      this.$success("Added " + count + " new nodes from file");

    },
    downloadConfig() {
      this.config.downloading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/config`, {
        responseType: "blob"
      })
        .then(response => {
          saveAs(response.data, `Config Sheet ${this.site.id}.pdf`);
        })
        .catch(error => {
          this.$error("Failed to generate config sheet", error);
        })
        .finally(() => {
          this.config.downloading = false;
        })
    },
    getUUID() {
      return uuidv4();
    },
    migrateNodes() {

      this.nodeMigration.processing = true;
      let selectedNodes = [];
      let selectedInputs = $('input:checked');
      selectedInputs.each((index, element) => {
        let row = $(element).parents('tr');
        let nodeId = row.attr('id');
        let linkId = row.attr('link-id');
        selectedNodes.push({ id: linkId, uuid: nodeId });
      })
      let nodeIds = [];
      selectedNodes.forEach((n) => {
        nodeIds.push(n.id);
      });

      axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/nodes/migrate`, {
        site_id: this.nodeMigration.site_id,
        nodes: nodeIds
      })
        .then(response => {
          if (response.data.success) {
            this.$success("Migrated nodes!");
          }
          this.nodeMigration.site_id = null;
          $('#closeMigrateNodeModal').click();
          this.$router.go();
        })
        .catch(error => {
          this.$error("Failed to migrate nodes.", error);
        })
        .finally(() => {
          this.nodeMigration.processing = false;
        });


    },
    prefillAddresses() {
      let firstNode = this.nodes[0];
      console.log(firstNode);
      let firstAddress = firstNode.address.split('.');
      let lastOctet = parseInt(firstAddress[3]);

      // Prefill any empty addresses
      this.nodes.forEach((node) => {
        if (node.address === '') {
          lastOctet++;
          node.address = `${firstAddress[0]}.${firstAddress[1]}.${firstAddress[2]}.${lastOctet}`;
        }
      });

    }
  },
  computed: {
    selected: function () {
      return $("input:checked").length;
    },
    changed: function () {
      return true
    },

    total: function () {
      return this.nodes.length;
    },
    enabledColumns: function () {
      let cnt = 0;
      Object.keys(this.columns).forEach((k) => {
        if (this.columns[k] === true) cnt++;
      });
      return cnt;
    },
    notDeletedNodes: function () {
      return this.nodes.filter((n) => {
        return !n.uuid.includes("DELETE_");
      });
    },
    notDeletedCredentials: function () {
      return this.credentials.filter((c) => {
        return !c.delete;
      })
    },
  }
}
</script>

<style scoped>
.hiddenRow {
  display: none;
}

td {
  padding: 0 !important;
}

table input:not([type=checkbox]) {
  background: transparent;
  border-bottom: none;
  border-top: none;
  border-right: none;
}

.checkbox-td {
  text-align: center;
  vertical-align: middle;
}

table .form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px) !important;
}

.brandNewRow>* .deleteButton {
  display: none;
}

.hidden {
  display: none;
}

.no-creds {
  background-color: #ffc1076a !important;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>